import React from "react";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import AspectRatio from "./AspectRatio";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    // Add border that contrasts lightly with background color.
    // We use boxShadow so that it's hidden around outer edge
    // due to container <Card> having overflow: hidden
    boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
    textAlign: "center",
  },
  imageContainer: {
    margin: "0 auto",
    maxWidth: "200px",
    marginBottom: "30px",
    "& img": {
      width: "100%",
    },
  },
}));

function FeaturesSection(props) {
  const classes = useStyles();

  const items = [
    {
      title: "BUT I HATE LOOKING FOR A JOB",
      body: `That's why I'll do it for you. Good jobs only. You tell me what you want as a developer and I will find it. I'll get the real pay range. I'm not a recruiter. I'm currently employed as a software engineer in Seattle. This is my employer-approved side hustle. That's right, a boutique technical recruiting agency for software developers in the Seattle area. I can do this because I negotiated for it. What do you want?`,
      image: "https://uploads.divjoy.com/undraw-directions_x53j.svg",
    },
    {
      title: "BUT I'M MAKING PRETTY GOOD MONEY ALREADY",
      body: "Oh are you? You're so happy you don't think you could ever be happier? Don't feel like you're being greedy to ask for more. You're not working for a charity. Trust me, you're still leaving a little money on the table for all those hard-up billionaires and venture capitalists.",
      image: "https://uploads.divjoy.com/undraw-stability_ball_b4ia.svg",
    },
    {
      title: "YES I USED A TEMPLATE",
      body: `These are just generic images that come with the template. Who cares? What's important is that if you've been working for the same company for more than two years, you're almost certainly being underpaid and I can get you a better offer.`,
      image: "https://uploads.divjoy.com/undraw-fish_bowl_uu88.svg",
    },
    {
      title: "WHY ARE YOU BEING SO NICE TO ME?",
      body: "Because I am you! I've also had frustrating experiences with recruiters and I've changed jobs to get better opportunities that included better compensation. Money is a tool, and if you use it thoughtfully, it will improve your life. So yeah,you want what you're worth. So do I. Get paid.",
      image: "https://uploads.divjoy.com/undraw-personal_settings_kihd.svg",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Card raised={false}>
          <Grid container={true}>
            {items.map((item, index) => (
              <Grid
                item={true}
                xs={12}
                md={6}
                className={classes.gridItem}
                key={index}
              >
                <Box p={6}>
                  <div className={classes.imageContainer}>
                    <AspectRatio ratio={4 / 3}>
                      <img src={item.image} alt={item.title} />
                    </AspectRatio>
                  </div>
                  <Typography variant="h4" gutterBottom={true}>
                    {item.title}
                  </Typography>
                  <Typography variant="subtitle1">{item.body}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Card>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
