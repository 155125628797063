import styles from '../styles/styles.css'
import herobg from '../assets/bgmntsnowJPG.png';
import roadahead from '../assets/road-ahead.png';
import misttreees from '../assets/IMG_4406.JPG';
import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import ClientsSection from "./../components/ClientsSection";
import TestimonialsSection from "./../components/TestimonialsSection";
import NewsletterSection from "./../components/NewsletterSection";
import CtaSection from "./../components/CtaSection";
import styled from 'styled-components';

const Root = styled.div`
font-family: impact;
  color: deeppink;
`
function IndexPage(props) {
  return (
    <>
      <Meta />
      <HeroSection
        bgColor="primary"
        size="large"
        bgImage={herobg}
        bgImageOpacity={.9}
        title={`YOU ARE WORTH MORE`}
        subtitle="Based on current market conditions for software developers"
        buttonText="GET PAID"
        buttonColor="default"
        buttonPath="/contact"
        style={{fontFamily: "Impact", color: "deeppink"}}
      />
      <FeaturesSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="DEVSGETPAID.COM PRESENTS"
        subtitle="The unreasonable effectiveness of getting paid"
      />
      {/*<TestimonialsSection*/}
      {/*  bgColor="default"*/}
      {/*  size="medium"*/}
      {/*  bgImage=""*/}
      {/*  bgImageOpacity={1}*/}
      {/*  title="Here's what people are saying"*/}
      {/*  subtitle=""*/}
      {/*/>*/}
              <CtaSection
        bgColor="black"
        size="large"
        bgImage={misttreees}
        bgImageOpacity={.75}
        title="Demand for our skills has never been higher, Don't be undervalued any longer!             "
        subtitle=""
        buttonText="GET PAID NOW"
        buttonColor="default"
        buttonPath="/contact"
      />
      <NewsletterSection
        bgColor="light"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        subtitle="Subscribe to my newsletter: I mostly rant about the incredible job market for developers and tips on applying, interviewing, negotiating offers, plus how to establish yourself in a new role."
        buttonText="Subscribe"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
      />
    </>
  );
}

export default IndexPage;
